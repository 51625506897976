<template>
  <div class="pa-5">
    <div v-if="isInitialized">

      <div class="d-flex justify-space-between">
        <div class="text-h5 text-nowrap text-truncate">
          {{ unit.shortName }} - {{ unit.title }}
        </div>
        <div v-if="false">
          <v-btn @click="goToSettings">Settings</v-btn>
        </div>
      </div>



      <v-row v-for="team in unit.teams" v-bind:key="team.id">
        <v-col cols="12">
          <v-card class="mt-4">
            <v-card-text class="d-flex">

              <v-list two-line class="flex-grow-1">
                <template v-for="(membership, index) in team.memberships">
                  <v-list-item v-bind:key="membership.id">
                    <v-list-item-avatar>
                      <v-img alt="avatar" src="/img/profile.png" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{ membership.user.firstname }}
                        {{ membership.user.lastname }}
                      </v-list-item-title>
                      <v-list-item-subtitle>{{ membership.group.name }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-list-item-action-text>{{ membership.user.email }}</v-list-item-action-text>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider v-if="index < team.memberships.length - 1" :key="index"/>
                </template>
              </v-list>

              <!-- Insert final project report widget here -->
              <se2a-final-project-report-widget v-if="!isPhaseTwoProject" :team="team" />

            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

    </div>
    <div v-else>
      <v-skeleton-loader
        type="card-avatar, article, actions"
      />
    </div>
  </div>
</template>

<script>

import unitDashboardQuery from '@/modules/project-management/graphql/UnitDashboard.graphql';
import {mapGetters} from "vuex";
import Se2aFinalProjectReportWidget
  from "@/modules/final-project-report/components/se2a-final-project-report-widget/se2a-final-project-report-widget";
import {defineComponent} from "vue";

export default defineComponent({
  name: 'project-management-dashboard',
  components: {Se2aFinalProjectReportWidget},
  data() {
    return {}
  },
  apollo: {
    unit: {
      query: unitDashboardQuery,
      variables() {
        return {
          id: this.active.id
        }
      }
    }
  },
  computed: {
    ...mapGetters('core', {
      coreLoading: 'isLoading'
    }),
    ...mapGetters('unit', {
      active: 'active'
    }),
    isPhaseTwoProject() {
      return this.active.projectPhase === 'p2';
    },
    isInitialized() {
      return !this.coreLoading && !this.$apollo.loading;
    }
  },
  methods: {
    goToSettings() {
      this.$router.push({
        name: 'unit-settings',
        params: {unitId: this.$router.currentRoute.params.unitId}
      })
    }
  }
});
</script>
