<template>
  <div class="pl-2">
    <v-sheet
      v-if="isFeatureActive"
      color="white"
      elevation="4"
      height="100%"
      outlined
      width="250px"
      class="text-center d-flex justify-space-around flex-column"
    >

      <div v-if="isSuccess">
        <div class="pb-4"><span class="title">Final Project Report</span></div>
        <v-btn class="pa-1 text-truncate" v-for="file in files" x-small max-width="200px" @click="downloadFile(file)">Download Report</v-btn>
        <div class="pt-2">
          <v-btn v-if="canUpload" color="green" x-small @click="openAdditionalFilesModal" :loading="isUploading">Upload additional file</v-btn>
        </div>
      </div>
      <div v-else-if="!isReview">
        <div class="pb-4"><span class="title">Final Project Report</span></div>
        <span v-if="!canUpload && isReady" class="subtitle-1">Not Available</span>
        <v-btn v-if="canUpload && isReady" color="green" small @click="openUploadModal" :loading="isUploading">Upload</v-btn>
      </div>
      <div v-else>
        <div class="pb-4"><span class="title">Final Project Report</span></div>
        <span class="subtitle-1">In Review</span>
        <v-btn v-if="canUpload" color="green" small @click="openAdditionalFilesModal" :loading="isUploading">Upload additional file</v-btn>
      </div>
    </v-sheet>


    <v-sheet
      v-else
      color="white"
      elevation="4"
      height="100%"
      outlined
      width="250px"
      class="text-center d-flex justify-space-around flex-column"
    >
      <div>
        <span class="title">UPCOMING:</span><br/>
        <span class="subtitle-1">Final Project Report</span>
      </div>
    </v-sheet>

    <v-snackbar v-model="uploadSuccessSnack">
      Upload of additional files was successful!
      <template v-slot:action="{ attrs }">
        <v-btn
            color="green"
            text
            v-bind="attrs"
            @click="uploadSuccessSnack = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import {defineComponent, onMounted, reactive, ref, watchEffect} from "vue";
import {CLIENT} from "@/api/client";
import {byApi, byApiId} from "@/api/helpers";
import {useGetters} from "vuex-composition-helpers";
import Intranet from "@/core/intranet";

export default defineComponent({
  name: "se2a-final-project-report-widget",
  props: {
    team: Object
  },
  setup(props) {
    const isFeatureActive = Intranet.features().isFeatureActive('INNET_FEATURE_FINAL_PROJECT_REPORT');

    const uploadSuccessSnack = ref(false);
    const exists = ref(false);
    const isReview = ref(false);
    const canUpload = ref(false);
    const isReady = ref(false);
    const isSuccess = ref(false);
    const files = ref([]);

    const { user } = useGetters('auth', ['user']);
    const { isVisible } = useGetters('modal', ['isVisible']);

    watchEffect(() => {
      if(!isVisible.value) {
        CLIENT.go(props.team.id).get().then(result => {
          if(!result.asData().finalProjectReport) {
            return;
          }

          CLIENT.go(result.asData().finalProjectReport).get().then(result => {
            isReview.value = result.asData().status === 'formal-review' || result.asData().status === 'coordinator-review';
            isSuccess.value = result.asData().status === 'success';
          })
        })
      }
    });

    onMounted(() => {
      if (user.value.memberships.filter(membership => membership.team === props.team.id).length > 0) {
        canUpload.value = true;
      }

      // Admins can always upload!
      if(user.value.roles.includes('ROLE_ADMIN')) {
        canUpload.value = true;
      }

      if(props.team.finalProjectReport) {
        exists.value = true;
        CLIENT.go(props.team.finalProjectReport.id).get().then(result => {
          isReview.value = result.asData().status === 'formal-review' || result.asData().status === 'coordinator-review';
          isSuccess.value = result.asData().status === 'success';
          files.value = result.asData().files;
          isReady.value = true;
        })
      } else {
        exists.value = false;
        isReady.value = true;
      }
    })

    const openUploadModal = () => {
      Intranet.modals().openModal('final-project-report', {teamId: props.team.id});

    };

    const openAdditionalFilesModal = () =>  {
      isUploading.value = true;
      Intranet.modals().openModal('final-project-report-additional', {
        reportIri: props.team.finalProjectReport.id,
        update: () => {
          isUploading.value = false;
          uploadSuccessSnack.value = true;
        }
      });
    }

    const fileRef = ref(null);
    const selectFile = () => {
      fileRef.value.click();
    }


    const isUploading = ref(false);
    const doUpload = () => {
      isUploading.value = true;
      console.log(fileRef.value.files);

      let data = new FormData();
      data.append('file', fileRef.value.files[0]);

      CLIENT.go(byApi('final_project_report_files')).postRaw(data, null).then(result => {
        console.log(result.asData());

        CLIENT.go(byApi('final_project_reports')).post({
          team: props.team.id,
          status: 'review',
          files: [result.asData()['@id']]
        }).then(fprResult => {
          isUploading.value = false;
          isReview.value = true;
        })
      })
    }

    const downloadFile = (file) => {
      CLIENT.go(file['@id'] + '/sign').post({}).then(result => {
        window.open(file['@id'] + '/download?token=' + result.asData()['token'], '_blank').focus();
      })
    }

    return {
      fileRef, selectFile, doUpload, isReview, isUploading, canUpload, openUploadModal, isVisible, isReady, isFeatureActive, isSuccess,
      files, downloadFile, openAdditionalFilesModal, uploadSuccessSnack
    }
  }
});
</script>

<style scoped>

</style>
